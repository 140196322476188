(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("styled-components"), require("@material-ui/core/styles"), require("@material-ui/core/FormControlLabel"), require("@material-ui/core/FormControl"), require("@material-ui/core/Button"), require("@material-ui/core/Radio"), require("@material-ui/core/RadioGroup"), require("@material-ui/core/MenuItem"), require("@material-ui/core/Select"), require("@material-ui/core/Switch"), require("@material-ui/core/TextField"), require("@material-ui/core/Checkbox"), require("@material-ui/core/FormHelperText"), require("@material-ui/core/CircularProgress"), require("@material-ui/core/Stepper"), require("@material-ui/core/Step"), require("@material-ui/core/StepLabel"), require("@material-ui/core/Modal"));
	else if(typeof define === 'function' && define.amd)
		define([, , "@material-ui/core/styles", "@material-ui/core/FormControlLabel", "@material-ui/core/FormControl", "@material-ui/core/Button", "@material-ui/core/Radio", "@material-ui/core/RadioGroup", "@material-ui/core/MenuItem", "@material-ui/core/Select", "@material-ui/core/Switch", "@material-ui/core/TextField", "@material-ui/core/Checkbox", "@material-ui/core/FormHelperText", "@material-ui/core/CircularProgress", "@material-ui/core/Stepper", "@material-ui/core/Step", "@material-ui/core/StepLabel", "@material-ui/core/Modal"], factory);
	else if(typeof exports === 'object')
		exports["\"@gnosis.pm/safe-react-components\""] = factory(require("react"), require("styled-components"), require("@material-ui/core/styles"), require("@material-ui/core/FormControlLabel"), require("@material-ui/core/FormControl"), require("@material-ui/core/Button"), require("@material-ui/core/Radio"), require("@material-ui/core/RadioGroup"), require("@material-ui/core/MenuItem"), require("@material-ui/core/Select"), require("@material-ui/core/Switch"), require("@material-ui/core/TextField"), require("@material-ui/core/Checkbox"), require("@material-ui/core/FormHelperText"), require("@material-ui/core/CircularProgress"), require("@material-ui/core/Stepper"), require("@material-ui/core/Step"), require("@material-ui/core/StepLabel"), require("@material-ui/core/Modal"));
	else
		root["\"@gnosis.pm/safe-react-components\""] = factory(root[undefined], root[undefined], root["@material-ui/core/styles"], root["@material-ui/core/FormControlLabel"], root["@material-ui/core/FormControl"], root["@material-ui/core/Button"], root["@material-ui/core/Radio"], root["@material-ui/core/RadioGroup"], root["@material-ui/core/MenuItem"], root["@material-ui/core/Select"], root["@material-ui/core/Switch"], root["@material-ui/core/TextField"], root["@material-ui/core/Checkbox"], root["@material-ui/core/FormHelperText"], root["@material-ui/core/CircularProgress"], root["@material-ui/core/Stepper"], root["@material-ui/core/Step"], root["@material-ui/core/StepLabel"], root["@material-ui/core/Modal"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__17__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__19__, __WEBPACK_EXTERNAL_MODULE__20__) {
return 