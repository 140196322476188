import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="12"
      viewBox="0 0 7 12">
      <path
        fill="#B2B5B2"
        fill-rule="evenodd"
        d="M.291 5.29l.06-.054L5.294.292c.389-.39 1.025-.39 1.414 0 .389.389.389 1.025 0 1.414L2.414 6l4.294 4.293c.389.389.389 1.025 0 1.414s-1.025.389-1.414 0L.341 6.756l-.05-.047c-.195-.195-.292-.452-.291-.71-.001-.257.096-.514.291-.71z"
      />
    </svg>
)

export default icon;
