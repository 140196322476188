import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M8.387 14.927c-.24.097-.507.097-.746.001-2.036-.815-3.566-1.917-4.529-3.278-.168-.228-.307-.447-.415-.657-.553-1.008-.752-2.46-.693-3.255V3.52c0-.58.491-1.038 1.07-.998.317.023.456.03.614.03 1.013 0 1.914-.263 2.746-.7.045-.023.09-.048.135-.074.214-.122.342-.208.595-.387l.104-.082.026-.022c.368-.361.95-.384 1.345-.053.87.732 2.14 1.318 3.665 1.318.159 0 .295-.007.614-.03.578-.04 1.07.418 1.07.998v4.286c.013.628-.039 1.323-.224 2.038-.094.359-.217.693-.376.997-.824 1.634-2.456 3.06-5.001 4.086zm3.218-4.992l.009-.016c.084-.16.156-.356.214-.578.13-.503.17-1.035.161-1.495l-.001-.076V4.546c-1.584-.062-2.933-.582-3.995-1.298-.141.095-.266.172-.43.266l-.196.108c-1.017.533-2.122.874-3.364.924l-.002 3.263c-.036.518.114 1.61.462 2.245.068.132.15.262.27.424.677.957 1.771 1.78 3.279 2.438 1.89-.828 3.033-1.867 3.593-2.981z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M12.38 21.935c-.227.086-.478.087-.705.001-3.157-1.185-5.515-2.776-6.99-4.733-.26-.33-.468-.635-.627-.93-.833-1.418-1.137-3.49-1.053-4.667V5.279c0-.58.49-1.037 1.069-.998.5.035.75.047 1.03.047 1.739 0 3.28-.423 4.702-1.12.081-.041.162-.083.242-.126.364-.195.606-.348 1.023-.626l.165-.12c.039-.03.065-.05.086-.07.36-.334.91-.356 1.296-.052 1.5 1.179 3.663 2.114 6.281 2.114.278 0 .53-.013 1.03-.047.577-.04 1.067.419 1.067.998v6.375l.002.063c.007.324 0 .687-.034 1.11-.05.625-.15 1.242-.312 1.83-.141.507-.326.977-.563 1.4-1.26 2.338-3.764 4.388-7.71 5.878zm5.951-6.832l.01-.016c.154-.276.282-.6.384-.966.125-.453.205-.946.245-1.453.028-.349.034-.647.029-.91l-.003-.104V6.328H18.9c-2.79 0-5.132-.882-6.899-2.09-.375.249-.636.408-1.006.606-.099.053-.199.105-.303.157-1.68.825-3.512 1.327-5.588 1.327h-.098l-.002 5.347c-.016.236.011.833.097 1.417.124.847.352 1.604.7 2.199.116.215.26.428.468.69 1.185 1.572 3.112 2.905 5.757 3.947 3.309-1.31 5.324-2.996 6.306-4.825z"
        />
      </g>
    </svg>
  )
};
