import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="4"
      viewBox="0 0 20 4">
      <g fill="#B2B5B2" fill-rule="evenodd">
        <rect width="4" height="4" x="16" rx="2" />
        <rect width="4" height="4" x="8" rx="2" />
        <rect width="4" height="4" rx="2" />
      </g>
    </svg>
)

export default icon;