import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10">
      <path
        fill="#B2B5B2"
        fill-rule="evenodd"
        d="M6 4h3c.552 0 1 .448 1 1s-.448 1-1 1H6v3c0 .552-.448 1-1 1s-1-.448-1-1V6H1c-.552 0-1-.448-1-1s.448-1 1-1h3V1c0-.552.448-1 1-1s1 .448 1 1v3z"
      />
    </svg>
)

export default icon;