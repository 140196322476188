import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="12"
      viewBox="0 0 7 12">
      <path
        fill="#B2B5B2"
        fill-rule="evenodd"
        d="M6.709 5.29l-.06-.054L1.706.292c-.39-.39-1.025-.39-1.414 0-.39.389-.39 1.025 0 1.414L4.586 6 .292 10.293c-.39.389-.39 1.025 0 1.414.389.389 1.025.389 1.414 0l4.953-4.951.05-.047c.195-.195.292-.452.29-.71.002-.257-.095-.514-.29-.71"
      />
    </svg>
)

export default icon;
