import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="7"
      viewBox="0 0 12 7">
      <path
        fill="#B2B5B2"
        fill-rule="evenodd"
        d="M6.709.291l.054.06 4.944 4.943c.389.389.389 1.025 0 1.414-.39.389-1.025.389-1.414 0L5.999 2.414 1.706 6.708c-.39.389-1.025.389-1.414 0-.39-.389-.39-1.025 0-1.414L5.242.341l.048-.05c.195-.195.452-.292.709-.291.258-.001.515.096.71.291z"
      />
    </svg>
  )

export default icon;
