import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="6"
      viewBox="0 0 6 6">
      <path
        fill="#008C73"
        fill-rule="evenodd"
        d="M3 0C1.347 0 0 1.347 0 3s1.347 3 3 3 3-1.347 3-3-1.347-3-3-3z"
      />
    </svg>
)

export default icon;