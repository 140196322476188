import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M4.854 4c-1.022 0-1.853.829-1.853 1.849v1.297c0 1.023.832 1.855 1.853 1.855h1.744c.328 0 .633.159.82.428l.117.169c.305.445 1.166 1.696 1.844 2.227l-.032-.176c-.136-.874-.13-1.253-.061-1.776.064-.499.489-.872.992-.872h.867c1.023 0 1.856-.826 1.856-1.841V5.849C13 4.829 12.168 4 11.146 4H4.854zm4.841 9.998c-.34 0-.69-.077-.992-.235H8.7c-.929-.485-1.839-1.637-2.627-2.762h-1.22C2.73 11.001 1 9.271 1 7.146V5.849C1 3.727 2.73 2 4.854 2h6.292C13.27 2 15 3.727 15 5.849V7.16c0 2.075-1.66 3.771-3.726 3.839.014.099.029.211.05.341l.028.159c.092.48.285 1.479-.479 2.114-.303.254-.734.385-1.178.385z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M7.282 5C5.472 5 4 6.472 4 8.281v3.428c0 1.814 1.472 3.29 3.282 3.29h2.616c.313 0 .608.147.797.396 1.593 2.101 2.627 2.994 3.202 3.372-.332-1.118-.34-2.078-.304-2.817.026-.533.465-.951 1-.951h2.126c1.81 0 3.281-1.467 3.281-3.27V8.281C20 6.472 18.528 5 16.72 5H7.281zm7.098 16c-.325 0-.71-.077-1.145-.304-1.06-.554-2.349-1.797-3.829-3.697H7.282C4.37 16.999 2 14.626 2 11.709V8.281C2 5.369 4.37 3 7.282 3h9.437C19.631 3 22 5.369 22 8.281v3.448c0 2.906-2.369 5.27-5.28 5.27h-1.125c.04.501.142 1.058.381 1.668.303.77.071 1.606-.565 2.036-.139.093-.503.297-1.03.297z"
        />
      </g>
    </svg>
  )
};
