import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M7.27 8.688c-.24 0-.468-.097-.637-.266L5.17 6.958c-.35-.35-.35-.92.002-1.272.352-.351.92-.35 1.272.002l.825.825 2.29-2.29c.353-.35.923-.35 1.274 0 .351.353.351.923 0 1.274L7.906 8.425c-.17.168-.4.264-.638.264"
        />
        <path
          className="icon-color"
          d="M4 3v9.131l3.446-2.299c.336-.225.774-.225 1.11 0L12 12.131V3H4zM3 15c-.162 0-.324-.039-.472-.118C2.203 14.708 2 14.369 2 14V2c0-.553.447-1 1-1h10c.553 0 1 .447 1 1v12c0 .369-.203.708-.528.882-.325.174-.72.154-1.027-.05l-4.444-2.966-4.446 2.966C3.388 14.943 3.194 15 3 15z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M10.729 13.135c-.266 0-.52-.105-.707-.293l-2.543-2.545c-.39-.391-.39-1.024 0-1.414.391-.391 1.023-.391 1.414 0l1.835 1.837 4.38-4.38c.39-.39 1.023-.39 1.413 0 .392.392.392 1.024 0 1.415l-5.086 5.087c-.187.188-.44.293-.707.293"
        />
        <path
          className="icon-color"
          d="M6 4v15.057l5.421-3.873c.348-.248.813-.248 1.163 0L18 19.056V4H6zm13 18c-.205 0-.408-.063-.582-.187l-6.416-4.586-6.421 4.586c-.305.218-.706.249-1.039.077C4.209 21.718 4 21.375 4 21V3c0-.553.447-1 1-1h14c.553 0 1 .447 1 1v18c0 .375-.209.718-.543.89-.144.074-.301.11-.457.11z"
        />
      </g>
    </svg>
  )
};
