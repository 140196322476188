import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M14.337 5.028c.141.302.262.616.36.94 1.123 3.698-.966 7.607-4.665 8.73-3.699 1.12-7.607-.968-8.728-4.666-1.123-3.699.965-7.607 4.665-8.73C6.634 1.103 7.318 1 8 1c.553 0 1 .448 1 1 0 .553-.447 1-1 1-.486 0-.975.072-1.451.217-2.642.8-4.134 3.592-3.332 6.235.802 2.64 3.593 4.132 6.234 3.331 2.642-.801 4.134-3.593 3.332-6.235-.035-.115-.074-.229-.117-.34l-.051.297c-.094.544-.612.91-1.156.815-.544-.094-.909-.612-.815-1.156l.462-2.668c.093-.537.6-.901 1.14-.817l2.655.413c.546.085.92.596.834 1.142-.084.546-.596.92-1.141.834l-.257-.04z"
        />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M5.305 8.482c-.47.29-.617.906-.328 1.376.29.47.906.617 1.376.328l2.17-1.334c.295-.182.475-.505.475-.852V5.01c0-.552-.448-1-1-1s-1 .448-1 1v2.43L5.305 8.482z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M19.856 7.608c.311.559.565 1.154.754 1.78 1.444 4.755-1.242 9.78-5.997 11.222-4.756 1.443-9.78-1.243-11.223-5.997C1.947 9.857 4.632 4.832 9.388 3.389c.856-.259 1.735-.388 2.612-.388.553 0 1 .447 1 1 0 .552-.447 1-1 1-.681 0-1.365.1-2.032.302-3.698 1.122-5.787 5.03-4.664 8.73 1.122 3.697 5.03 5.785 8.728 4.664 3.698-1.122 5.787-5.03 4.664-8.73-.155-.51-.364-.996-.623-1.449l-.248 1.432c-.094.544-.612.91-1.156.815-.544-.094-.91-.612-.815-1.156l.616-3.557c.093-.538.6-.902 1.14-.818l3.54.551c.546.085.92.597.835 1.142-.085.546-.597.92-1.142.835l-.987-.154z"
        />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M8.582 12.927c-.47.29-.618.905-.328 1.376.29.47.905.617 1.375.327l2.892-1.779c.296-.182.476-.504.476-.851V8.015c0-.553-.447-1-1-1-.552 0-1 .447-1 1v3.426l-2.415 1.486z"
        />
      </g>
    </svg>
  )
};
