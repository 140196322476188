import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M3.238 6.4c-.142 0-.286-.03-.423-.095-.501-.234-.716-.829-.481-1.329 1.212-2.587 3.268-3.924 6.113-3.973 3.863-.097 5.386 2.644 5.449 2.758.264.486.084 1.093-.401 1.357-.486.26-1.088.086-1.352-.394-.05-.088-1.03-1.722-3.56-1.722l-.101.001c-2.695.046-3.733 1.533-4.338 2.821-.17.363-.53.576-.906.576"
        />
        <path
          className="icon-color"
          d="M8.552 15.002c-.149 0-.3-.033-.443-.104-.494-.245-.698-.845-.453-1.34 1.11-2.239 1.852-4.79 1.765-6.067-.069-1.034-.662-1.015-.876-1.022-.51.005-.85.15-1.08.88-.166.526-.726.817-1.255.652-.526-.165-.819-.727-.653-1.254.458-1.452 1.511-2.261 2.964-2.279h.044c1.59 0 2.734 1.154 2.852 2.888.13 1.895-.895 4.927-1.968 7.09-.174.353-.529.556-.897.556M13.196 12.83c-.108 0-.22-.017-.326-.054-.523-.18-.8-.75-.62-1.271.534-1.543.766-2.875.756-4.317-.004-.552.44-1.003.992-1.007h.008c.548 0 .996.442 1 .992.012 1.679-.254 3.217-.865 4.984-.142.414-.53.674-.945.674"
        />
        <path
          className="icon-color"
          d="M4.663 14.053c-.16 0-.323-.039-.474-.12-.486-.262-.667-.868-.406-1.355.006-.01.666-1.247 1.076-2.774.144-.534.699-.849 1.224-.707.534.144.851.691.707 1.225-.473 1.762-1.216 3.148-1.247 3.207-.18.334-.524.524-.88.524M2.001 11.09c-.09 0-.18-.012-.27-.037-.533-.15-.843-.7-.694-1.234.145-.513.248-.958.322-1.28.04-.166.071-.303.097-.403.14-.535.685-.859 1.22-.716.534.14.854.685.716 1.22l-.084.351c-.08.344-.19.82-.345 1.37-.124.441-.525.73-.962.73"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M21.155 17.238c-.08 0-.16-.01-.24-.029-.536-.132-.864-.674-.732-1.21.012-.049 1.169-4.875-.374-8.28-2.21-4.877-6.945-4.727-7.152-4.717-.536.012-1.018-.402-1.045-.952-.026-.549.395-1.017.944-1.046.252-.012 6.287-.256 9.076 5.89 1.832 4.042.548 9.36.493 9.584-.113.455-.521.76-.97.76M2 15.65c-.2 0-.4-.06-.577-.183-.45-.319-.558-.943-.24-1.394.824-1.167 1.096-2.23 1.363-4.034C3.55 3.214 8.549 1.659 8.76 1.597c.529-.16 1.087.145 1.244.675.155.528-.145 1.083-.67 1.24-.175.055-4 1.312-4.812 6.819-.252 1.716-.555 3.267-1.705 4.896-.196.277-.505.423-.819.423"
        />
        <path
          className="icon-color"
          d="M6.658 13.872c-.063 0-.128-.006-.192-.019-.542-.105-.896-.63-.79-1.172 0-.006.173-.895.375-2.116.61-3.732 2.967-5.943 6.316-5.943l.15.001c3.944.078 5.475 3.001 5.538 3.126.25.492.054 1.094-.438 1.345-.491.248-1.088.055-1.341-.432-.053-.098-1.1-1.985-3.798-2.039-3.14-.063-4.13 2.286-4.454 4.267-.207 1.258-.386 2.173-.386 2.173-.092.478-.511.81-.98.81"
        />
        <path
          className="icon-color"
          d="M10.891 22.994c-.199 0-.4-.06-.576-.183-.45-.319-.559-.942-.24-1.394 2.216-3.139 3.446-7.684 3.482-9.757.006-.319-.042-.755-.3-1.019-.212-.213-.505-.258-.714-.258-.568 0-1.048.47-1.048 1.027 0 .553-.447 1-1 1-.552 0-1-.447-1-1 0-1.669 1.368-3.027 3.048-3.027.836 0 1.596.304 2.14.856.41.42.895 1.185.874 2.456-.047 2.674-1.53 7.593-3.848 10.876-.195.276-.504.423-.818.423M15.75 22.017c-.16 0-.322-.038-.473-.12-.486-.261-.669-.867-.407-1.354 1.24-2.306 2.426-5.963 2.276-8.93-.027-.551.398-1.021.95-1.05.536-.041 1.02.397 1.05.95.178 3.58-1.243 7.613-2.514 9.978-.18.336-.526.526-.882.526"
        />
        <path
          className="icon-color"
          d="M6.738 21.637c-.247 0-.493-.091-.687-.273-.401-.38-.42-1.012-.04-1.414 1.498-1.585 2.429-3.336 2.929-5.51.124-.537.66-.875 1.198-.75.539.124.875.66.75 1.198-.582 2.535-1.67 4.58-3.423 6.436-.197.208-.461.313-.727.313M3.942 19.22c-.28 0-.558-.115-.755-.344-.362-.417-.317-1.048.1-1.41.86-.748 1.438-1.882 1.444-1.892.248-.494.849-.693 1.342-.445.493.247.692.846.446 1.34-.03.06-.754 1.493-1.922 2.507-.188.164-.423.245-.655.245"
        />
      </g>
    </svg>
  )
};
