import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M6.013 10.006c-.367 0-.72-.202-.896-.553-.247-.493-.046-1.093.448-1.342l1.442-.721V5.015c0-.553.447-1 1-1 .553 0 1 .447 1 1v2.992c0 .379-.214.725-.553.895L6.459 9.9c-.143.07-.296.105-.446.105"
        />
        <path
          className="icon-color"
          d="M8.004 3C5.244 3 3 5.245 3 8.005c0 2.759 2.245 5.004 5.004 5.004 2.76 0 5.005-2.245 5.005-5.004C13.009 5.245 10.764 3 8.004 3m0 12.009C4.142 15.009 1 11.867 1 8.005 1 4.143 4.142 1 8.004 1c3.862 0 7.005 3.143 7.005 7.005s-3.143 7.004-7.005 7.004"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M8.997 15.004c-.323 0-.64-.156-.833-.445-.307-.46-.183-1.08.277-1.387l2.56-1.707V7.014c0-.553.446-1 1-1 .552 0 1 .447 1 1V12c0 .334-.168.647-.446.832l-3.004 2.004c-.17.113-.364.168-.554.168"
        />
        <path
          className="icon-color"
          d="M12 4c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8m0 18C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10"
        />
      </g>
    </svg>
  )
};
