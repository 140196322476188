import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M8 5.996c.553 0 1 .447 1 1v.998c0 .553-.447 1-1 1-.552 0-1-.447-1-1v-.998c0-.553.448-1 1-1M8 9.895c.607 0 1.101.492 1.101 1.1 0 .607-.494 1.1-1.1 1.1-.608 0-1.1-.493-1.1-1.1 0-.608.492-1.1 1.1-1.1"
        />
        <path
          className="icon-color"
          d="M8 0c-.383 0-.766.193-.975.581L.133 13.373c-.396.734.138 1.624.974 1.624h13.786c.836 0 1.37-.89.974-1.624L8.974.581C8.766.193 8.384 0 8 0m0 3l5.386 9.997H2.613L8 3"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M6.066 19.998c.292-2.109 1.506-3.209 2.686-4.276 1.101-.997 2.24-2.028 2.251-3.73-.01-1.688-1.15-2.719-2.25-3.716-.105-.092-.208-.187-.31-.282h7.115c-.102.095-.205.19-.308.282-1.1.997-2.24 2.028-2.25 3.73.01 1.688 1.148 2.719 2.25 3.716 1.18 1.067 2.394 2.167 2.686 4.276H6.066zM17.936 4c-.112.812-.364 1.474-.696 2.042-.078-.02-.153-.048-.237-.048H6.731C6.414 5.436 6.175 4.789 6.066 4h11.87zm-1.344 10.238c-1.014-.918-1.588-1.474-1.593-2.232.005-.772.58-1.329 1.593-2.247 1.44-1.303 3.411-3.087 3.411-6.759 0-.553-.448-1-1-1H5c-.553 0-1 .447-1 1 0 3.672 1.97 5.456 3.41 6.759 1.013.918 1.588 1.475 1.593 2.233-.005.772-.58 1.328-1.593 2.246C5.97 15.542 4 17.326 4 20.998c0 .553.447 1 1 1h14.003c.552 0 1-.447 1-1 0-3.672-1.972-5.457-3.41-6.76z"
        />
      </g>
    </svg>
  )
};
