import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="5"
      viewBox="0 0 9 5">
      <path
        fill="#5D6D74"
        d="M3.858 4.754c.355.328.93.328 1.284 0l3.59-3.32C9.305.906 8.899 0 8.09 0H.91C.101 0-.305.907.268 1.436l3.59 3.319z"
      />
    </svg>
)

export default icon;
