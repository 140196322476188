import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M14.193 2.28c-.545-.116-1.07.243-1.178.783l-.257 1.287C11.65 2.9 9.921 2 8.005 2 5.71 2 3.586 3.336 2.591 5.403c-.239.498-.029 1.096.47 1.335.5.241 1.095.028 1.334-.469C5.058 4.89 6.475 4 8.005 4c1.31 0 2.493.626 3.228 1.637L9.73 5.445c-.55-.059-1.05.317-1.118.865-.07.548.316 1.05.864 1.12l3.665.468c.043.005.086.008.128.008.47 0 .885-.332.98-.804l.729-3.646c.108-.542-.244-1.069-.784-1.177M12.944 9.27c-.496-.239-1.095-.03-1.336.466C10.944 11.111 9.53 12 8.005 12c-1.343 0-2.55-.659-3.283-1.715l1.526.18c.55.07 1.046-.327 1.11-.876.066-.546-.327-1.045-.876-1.11l-3.635-.43c-.508-.06-.995.287-1.097.797l-.73 3.647c-.107.542.244 1.068.785 1.176.067.014.131.02.198.02.466 0 .884-.328.979-.804l.252-1.255C4.34 13.095 6.078 14 8.003 14c2.287 0 4.41-1.332 5.405-3.394.24-.497.032-1.095-.466-1.335"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M21.3 5.373c-.528-.17-1.089.117-1.26.642l-.397 1.22C18.03 4.638 15.178 3 12.003 3c-4.195 0-7.797 2.848-8.76 6.925-.127.537.206 1.076.745 1.203.077.017.154.026.23.026.453 0 .863-.309.973-.77C5.94 7.214 8.741 5 12.003 5c2.491 0 4.71 1.308 5.96 3.359l-1.55-.476c-.53-.164-1.089.136-1.25.662-.163.528.134 1.088.661 1.25l3.687 1.133c.098.03.197.044.294.044.422 0 .813-.269.951-.691l1.186-3.647c.17-.526-.118-1.09-.642-1.261M20.046 12.571c-.549-.107-1.069.247-1.175.788C18.23 16.63 15.341 19 12.003 19 9.52 19 7.3 17.693 6.05 15.646l1.53.471c.53.165 1.087-.135 1.25-.662.161-.529-.135-1.088-.663-1.25L4.48 13.071c-.523-.16-1.076.128-1.246.647l-1.185 3.65c-.17.523.117 1.088.643 1.26.102.032.206.047.308.047.422 0 .814-.268.951-.69l.402-1.237C5.965 19.354 8.827 21 12.003 21c4.29 0 8.004-3.05 8.831-7.254.107-.542-.247-1.069-.788-1.175"
        />
      </g>
    </svg>
  )
};
