import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14">
      <path
        fill="#B2B5B2"
        fill-rule="evenodd"
        d="M7 10c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3m6.068-4.209l-.3-.03c-.683-.068-1-.437-1.137-.67-.068-.255-.11-.74.316-1.286l.199-.242c.54-.607.354-.963-.035-1.352l-.322-.32c-.39-.39-.744-.576-1.353-.037l-.235.192c-.535.44-1.025.397-1.285.328-.232-.135-.609-.45-.677-1.14l-.03-.3C8.16.12 7.777 0 7.228 0h-.456c-.55 0-.932.121-.98.933l-.03.31c-.084.686-.458 1-.686 1.133-.261.068-.747.105-1.277-.33l-.235-.192c-.609-.539-.964-.353-1.353.036l-.322.321c-.389.39-.575.745-.035 1.352l.2.242c.424.545.383 1.031.315 1.286-.137.233-.454.602-1.137.67l-.3.03c-.811.05-.932.431-.932.981v.456c0 .55.121.933.932.98l.31.03c.686.085 1 .458 1.133.686.068.262.105.748-.33 1.278l-.192.235c-.539.607-.353.963.036 1.352l.322.32c.39.39.744.576 1.353.037l.242-.2c.539-.421 1.02-.384 1.277-.317.229.135.596.449.679 1.128l.03.31c.048.812.43.933.98.933h.456c.55 0 .932-.121.981-.932l.03-.301c.067-.683.437-1 .67-1.137.255-.067.741-.11 1.285.316l.242.2c.609.539.964.354 1.353-.036l.322-.321c.389-.39.575-.745.036-1.352l-.192-.235c-.435-.53-.398-1.016-.33-1.278.133-.228.447-.601 1.133-.686l.31-.03c.811-.047.932-.43.932-.98v-.456c0-.55-.121-.932-.932-.98"
      />
    </svg>
)

export default icon;
