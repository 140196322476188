import React from 'react';

const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="7"
      viewBox="0 0 12 7">
      <path
        fill="#B2B5B2"
        fill-rule="evenodd"
        d="M6.709 6.709c-.195.195-.452.292-.71.29-.257.002-.514-.095-.71-.29l-.046-.05L.292 1.706c-.39-.39-.39-1.025 0-1.414.389-.39 1.025-.39 1.414 0l4.293 4.294L10.293.292c.389-.39 1.025-.39 1.414 0 .389.389.389 1.025 0 1.414L6.763 6.649l-.054.06z"
      />
    </svg>
)

export default icon;
