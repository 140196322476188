import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M8 3c.92 0 1.666.747 1.666 1.666 0 .919-.747 1.666-1.666 1.666-.919 0-1.666-.747-1.666-1.666C6.334 3.747 7.081 3 8 3m2.75 4.599c-.123-.061-.254-.107-.382-.159.788-.672 1.298-1.66 1.298-2.774C11.666 2.645 10.021 1 8 1 5.98 1 4.334 2.645 4.334 4.666c0 1.109.506 2.093 1.287 2.766-2.726 1.08-3.91 3.981-4.58 6.283-.155.53.148 1.086.68 1.24.092.027.187.04.28.04.433 0 .832-.283.96-.72 1.078-3.7 2.585-5.278 5.037-5.278.71 0 1.324.131 1.877.4.497.242 1.094.035 1.337-.461.241-.497.035-1.095-.462-1.337M9.05 13.372l.008 1.341c0 .151.123.273.274.273h1.333c.073 0 .143-.029.195-.08l4.028-4.028c.158-.158.158-.415 0-.573l-1.182-1.182c-.144-.145-.378-.145-.522 0L9.13 13.176c-.052.051-.081.122-.08.196"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M12.003 4c1.654 0 3 1.345 3 3 0 1.654-1.346 3-3 3s-3-1.346-3-3c0-1.655 1.346-3 3-3m4.311 8.069c-.58-.323-1.202-.565-1.855-.741C15.97 10.467 17.003 8.86 17.003 7c0-2.757-2.243-5-5-5s-5 2.243-5 5c0 1.853 1.025 3.455 2.527 4.319-4.483 1.172-6.36 5.529-7.49 9.406-.154.53.15 1.086.68 1.24.094.028.188.04.28.04.434 0 .833-.283.96-.719 1.692-5.804 4.097-8.281 8.04-8.281 1.272 0 2.363.265 3.337.81.481.268 1.091.096 1.361-.385.27-.482.097-1.092-.384-1.361M13.399 20.385l.008 1.342c0 .151.123.273.274.273h1.333c.073 0 .143-.029.195-.08l4.028-4.029c.158-.158.158-.414 0-.572l-1.182-1.183c-.144-.144-.378-.144-.522 0L13.48 20.19c-.052.051-.081.122-.081.195M20.442 16.686l1.479-1.479c.11-.11.11-.29 0-.402l-1.328-1.326c-.11-.11-.291-.11-.402 0l-1.479 1.478c-.111.111-.111.292 0 .403l1.326 1.326c.111.111.293.111.404 0"
        />
      </g>
    </svg>
  )
};
