import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M8 1c-.258 0-.517.137-.644.41L5.764 5.095c-.101.215-.302.365-.536.4l-3.624.558C1.033 6.14.8 6.835 1.2 7.249l2.672 2.469c.156.16.228.386.19.607l-.912 3.85c-.074.458.29.825.702.825.114 0 .232-.028.344-.091l3.458-1.76c.108-.06.226-.09.345-.09.119 0 .238.03.346.09l3.395 1.76c.113.063.23.09.345.09.41 0 .775-.366.7-.824l-.85-3.85c-.036-.221.035-.446.192-.607l2.67-2.47c.402-.413.17-1.107-.401-1.195l-3.625-.557c-.234-.036-.435-.186-.535-.401L8.644 1.41C8.517 1.137 8.259 1 8 1m0 3.96l.4.929.025.052c.384.822 1.148 1.395 2.044 1.533l.978.149-.676.626c-.027.023-.053.049-.078.074-.594.613-.868 1.48-.731 2.323l.02.11.247 1.117-.946-.49c-.393-.211-.836-.323-1.283-.323-.443 0-.883.11-1.274.318l-.973.495.258-1.086.027-.141c.137-.842-.137-1.71-.73-2.322-.026-.025-.051-.051-.08-.076l-.674-.625.977-.15c.897-.137 1.66-.71 2.044-1.532l.025-.052.4-.928"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M12 2c-.369 0-.738.195-.92.586L8.805 7.851c-.144.306-.43.521-.766.573l-5.178.795c-.814.125-1.148 1.118-.574 1.71l3.817 3.526c.223.23.324.552.273.868l-1.304 5.5c-.106.655.415 1.18 1.002 1.18.163 0 .331-.041.492-.13l4.94-2.515c.153-.085.323-.128.493-.128.17 0 .34.043.493.128l4.851 2.515c.162.089.33.13.492.13.588 0 1.108-.525 1.002-1.18l-1.215-5.5c-.052-.316.05-.638.273-.868l3.816-3.526c.574-.592.241-1.585-.573-1.71l-5.179-.795c-.335-.052-.622-.267-.765-.573L12.92 2.586C12.737 2.195 12.37 2 12 2m0 3.499l1.36 3.145c.006.017.015.035.023.052.427.914 1.276 1.552 2.273 1.704l3.156.485-2.274 2.101-.077.076c-.66.68-.965 1.646-.813 2.583.006.037.014.074.022.11l.748 3.385-2.985-1.547c-.44-.238-.934-.363-1.433-.363-.496 0-.988.124-1.425.358L7.527 19.14l.796-3.355.03-.141c.15-.937-.154-1.902-.815-2.582-.024-.026-.05-.051-.077-.076l-2.273-2.101 3.155-.485c.997-.152 1.846-.789 2.273-1.703.01-.017.017-.035.025-.053l1.36-3.145"
        />
      </g>
    </svg>
  )
};
