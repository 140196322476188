import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M11.15 12.9c-.003.05-.044.078-.123.1H4.98c-.078-.013-.098-.05-.1-.12L4.415 6H11.6l-.449 6.9zM6.002 3.615c0-.334.282-.616.617-.616h2.768c.335 0 .617.282.617.616V4H6.002v-.384zM14 4h-1.996v-.384C12.004 2.173 10.831 1 9.387 1H6.62C5.175 1 4.002 2.173 4.002 3.616V4H2c-.553 0-1 .447-1 1 0 .552.447 1 1 1h.41l.471 6.995c.054 1.116.99 2.006 2.074 2.006h6.048c1.101.011 2.09-.873 2.144-1.989L13.604 6H14c.552 0 1-.448 1-1 0-.553-.448-1-1-1z"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M10 18.01c.553 0 1-.447 1-1v-6c0-.553-.447-1-1-1-.553 0-1 .447-1 1v6c0 .553.447 1 1 1M14.001 18.01c.553 0 1-.447 1-1v-6c0-.553-.447-1-1-1-.553 0-1 .447-1 1v6c0 .553.447 1 1 1"
        />
        <path
          className="icon-color"
          d="M16.513 19.55c-.013.255-.258.454-.497.452H7.969c-.23-.026-.461-.196-.474-.458L6.847 8h10.287l-.621 11.55zM9.997 4.617c0-.334.283-.616.617-.616h2.768c.34 0 .617.277.617.616V6H9.997V4.616zM20 6h-1.738c-.006 0-.012-.005-.02-.005-.011-.001-.021.005-.034.005h-2.21V4.616C15.999 3.174 14.827 2 13.383 2h-2.768C9.17 2 7.997 3.174 7.997 4.616V6H4c-.552 0-1 .447-1 1 0 .553.448 1 1 1h.845l.653 11.647c.063 1.31 1.16 2.356 2.44 2.356l.054-.001h8.057c1.3 0 2.399-1.044 2.462-2.348L19.137 8H20c.553 0 1-.447 1-1 0-.553-.447-1-1-1z"
        />
      </g>
    </svg>
  )
};
