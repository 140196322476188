import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M8 16c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm0-2c-3.314 0-6-2.686-6-6s2.686-6 6-6 6 2.686 6 6-2.686 6-6 6z"
        />
        <path
          className="icon-color"
          d="M8 10.142c-1.182 0-2.144-.962-2.144-2.143 0-1.18.962-2.143 2.143-2.143 1.181 0 2.143.962 2.143 2.143 0 1.181-.962 2.143-2.143 2.143m4.333-3.006l-.214-.021c-.488-.049-.714-.312-.812-.479-.048-.182-.078-.529.226-.918l.142-.173c.386-.434.253-.688-.025-.966l-.23-.23c-.278-.277-.531-.41-.966-.025l-.168.137c-.382.314-.732.284-.917.235-.166-.097-.435-.322-.484-.815l-.021-.215C8.828 3.086 8.554 3 8.162 3h-.326c-.392 0-.665.086-.7.666l-.021.222c-.06.49-.327.714-.49.81-.187.048-.534.074-.912-.237l-.168-.137c-.434-.385-.688-.252-.966.026l-.23.23c-.278.277-.41.531-.025.965l.142.173c.304.389.275.736.226.918-.098.167-.324.43-.812.479l-.214.021c-.58.035-.666.308-.666.7v.326c0 .393.086.666.666.7l.221.021c.49.061.714.327.81.49.048.187.074.535-.236.913l-.137.168c-.385.434-.253.688.025.966l.23.229c.278.278.532.41.966.026l.173-.143c.385-.3.729-.274.912-.227.163.097.426.321.485.806l.021.222c.035.58.308.666.7.666h.326c.392 0 .666-.087.7-.666l.022-.215c.048-.488.312-.714.479-.812.182-.048.529-.078.917.226l.173.143c.435.385.688.252.966-.026l.23-.23c.278-.277.41-.531.026-.965l-.138-.168c-.31-.378-.284-.726-.235-.913.095-.163.32-.429.809-.49l.221-.02c.58-.035.666-.308.666-.701v-.325c0-.393-.086-.666-.666-.7"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          fillRule="nonzero"
          d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z"
        />
        <path
          className="icon-color"
          d="M11.999 14.57c-1.418 0-2.571-1.153-2.571-2.571 0-1.417 1.153-2.571 2.571-2.571 1.418 0 2.571 1.154 2.571 2.571 0 1.418-1.153 2.571-2.571 2.571m5.2-3.606l-.257-.026c-.585-.058-.857-.375-.975-.574-.058-.219-.093-.636.271-1.103l.17-.207c.464-.52.304-.825-.03-1.159l-.275-.275c-.334-.333-.638-.493-1.159-.03l-.201.164c-.459.376-.879.34-1.102.28-.199-.115-.522-.386-.58-.977l-.026-.257c-.042-.696-.37-.8-.84-.8h-.391c-.471 0-.8.104-.84.8l-.026.265c-.072.588-.393.858-.588.972-.224.058-.64.09-1.095-.283l-.201-.165c-.521-.462-.825-.302-1.159.031l-.276.275c-.333.334-.493.639-.03 1.159l.17.207c.365.467.33.884.272 1.103-.118.2-.39.516-.975.574l-.257.026c-.695.042-.799.369-.799.84v.39c0 .472.104.8.799.841l.266.025c.588.073.857.393.97.588.059.225.09.641-.282 1.096l-.165.2c-.462.521-.302.826.031 1.16l.276.275c.334.334.638.493 1.159.03l.207-.17c.462-.362.875-.33 1.095-.272.196.115.51.384.582.966l.026.267c.04.695.369.799.84.799h.39c.471 0 .8-.104.841-.799l.026-.258c.057-.585.375-.857.574-.975.219-.057.635-.093 1.102.271l.207.172c.521.462.825.303 1.159-.031l.276-.275c.333-.334.493-.639.03-1.16l-.164-.2c-.373-.455-.341-.871-.283-1.096.114-.195.383-.515.971-.588l.266-.025c.695-.04.799-.37.799-.84v-.39c0-.472-.104-.8-.799-.841"
        />
      </g>
    </svg>
  )
};
