import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M5.299 5.4c.387 0 .7-.313.7-.7 0-.387-.313-.7-.7-.7h-1.4c-.386 0-.7.313-.7.7 0 .006.003.01.003.015 0 .003-.004.005-.004.008v6.544c0 .004.005.006.005.01 0 .007-.004.013-.004.02 0 .387.314.701.7.701h1.4c.387 0 .7-.314.7-.7 0-.387-.313-.7-.7-.7h-.7V8.704h.7c.387 0 .7-.314.7-.7 0-.387-.313-.7-.7-.7h-.7V5.4h.7zM14.4 4c-.388 0-.7.313-.7.7v2.605h-1.4V4.7c0-.387-.315-.7-.702-.7-.386 0-.699.313-.699.7v6.592c0 .387.313.7.7.7.386 0 .7-.313.7-.7V8.705h1.4v2.587c0 .387.313.7.7.7.387 0 .7-.313.7-.7V4.7c0-.387-.313-.7-.7-.7M9.501 4H7.4c-.386 0-.7.313-.7.7 0 .387.314.7.7.7h.351v5.898c0 .387.313.7.699.7.387 0 .7-.313.7-.7V5.4h.351c.387 0 .699-.313.699-.7 0-.387-.312-.7-.699-.7M1.799 10.498c-.44 0-.8.358-.8.8 0 .44.36.8.8.8.44 0 .8-.36.8-.8 0-.442-.36-.8-.8-.8"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M8.1 9c.553 0 1-.448 1-1 0-.553-.447-1-1-1h-2c-.553 0-1 .447-1 1 0 .062.024.115.035.173-.014.057-.035.112-.035.174v7.472c0 .032.014.058.018.088-.003.03-.018.057-.018.088 0 .552.447 1 1 1h2c.553 0 1-.448 1-1 0-.553-.447-1-1-1h-1V13h1c.553 0 1-.447 1-1 0-.553-.447-1-1-1h-1V9h1zM21.1 7c-.553 0-1 .447-1 1v3h-2V8c0-.553-.447-1-1-1-.553 0-1 .447-1 1v8c0 .552.447 1 1 1 .553 0 1-.448 1-1v-3h2v3c0 .552.447 1 1 1 .553 0 1-.448 1-1V8c0-.553-.447-1-1-1M14.102 7h-3c-.553 0-1 .447-1 1 0 .552.447 1 1 1h.5v6.995c0 .552.447 1 1 1 .553 0 1-.448 1-1V9h.5c.553 0 1-.448 1-1 0-.553-.447-1-1-1M3.1 14.896c-.606 0-1.1.493-1.1 1.1 0 .606.494 1.099 1.1 1.099.606 0 1.1-.493 1.1-1.1 0-.606-.494-1.1-1.1-1.1"
        />
      </g>
    </svg>
  )
};
