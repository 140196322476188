import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M12.617 11.518l-2.557-1.01c-.51-.203-1.094.048-1.297.563-.203.513.05 1.094.563 1.297l.29.114c-.31.062-.627.104-.946.104h-.009c-1.688-.004-3.265-.913-4.117-2.373-.278-.476-.89-.637-1.367-.36-.478.279-.638.892-.36 1.368 1.208 2.07 3.446 3.36 5.84 3.365h.013c.611 0 1.218-.086 1.801-.246l-.116.31c-.193.517.07 1.094.585 1.288.116.043.235.063.352.063.404 0 .786-.247.936-.648l.958-2.554c.192-.51-.06-1.081-.569-1.281M11.452 3.001c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1m-7.93-.005c0-.549.447-.996.995-.996.55 0 .996.447.996.996 0 .543-.439.984-.98.992l-.03-.003-.01.001c-.537-.014-.97-.45-.97-.99m12.41 5.427c-.43-1.148-1.17-2.058-2.073-2.651.367-.498.592-1.106.592-1.771 0-1.654-1.346-3-3-3s-3 1.346-3 3c0 .666.226 1.276.594 1.774-.228.151-.446.32-.65.511C8 5.659 7.501 5.142 6.93 4.756c.363-.495.584-1.101.584-1.76C7.513 1.344 6.17 0 4.517 0c-1.65 0-2.995 1.344-2.995 2.996 0 .653.217 1.254.574 1.747C1.212 5.331.487 6.228.063 7.355c-.193.518.07 1.094.585 1.289.116.043.235.063.352.063.404 0 .786-.247.936-.648.47-1.251 1.466-2.061 2.543-2.072.014 0 .026.004.038.004l.023-.002c1.071.019 2.061.825 2.528 2.07.01.026.032.044.044.069-.043.1-.096.192-.134.295-.193.517.07 1.094.585 1.288.517.189 1.093-.067 1.288-.585.48-1.278 1.502-2.104 2.605-2.104 1.102 0 2.124.826 2.603 2.104.152.401.533.648.937.648.117 0 .235-.02.352-.063.516-.194.778-.771.584-1.288"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M16.361 16.79l-3.285-1.298c-.513-.204-1.096.05-1.296.563-.204.513.049 1.094.562 1.296l1.165.461c-.675.235-1.386.37-2.11.37h-.014c-2.27-.005-4.39-1.228-5.537-3.19-.277-.477-.89-.639-1.367-.36-.478.278-.639.89-.36 1.367 1.503 2.573 4.284 4.176 7.26 4.183h.019c.956 0 1.896-.179 2.79-.493l-.36.961c-.194.517.068 1.093.586 1.287.114.043.232.064.35.064.405 0 .786-.247.937-.65l1.23-3.282c.191-.51-.062-1.08-.57-1.28M15.793 5.682c.68 0 1.23.552 1.23 1.23 0 .678-.55 1.23-1.23 1.23-.678 0-1.229-.552-1.229-1.23 0-.678.551-1.23 1.23-1.23M8.204 4c.678 0 1.23.552 1.23 1.23 0 .679-.552 1.23-1.23 1.23-.679 0-1.23-.551-1.23-1.23 0-.678.551-1.23 1.23-1.23m12.73 9.007c-.626-1.668-1.81-2.906-3.228-3.508.794-.588 1.316-1.525 1.316-2.587 0-1.781-1.449-3.23-3.23-3.23-1.78 0-3.229 1.449-3.229 3.23 0 1.062.522 1.999 1.316 2.587-.397.168-.778.374-1.13.639-.66-1.07-1.578-1.874-2.632-2.32.794-.59 1.317-1.526 1.317-2.588 0-1.78-1.45-3.23-3.23-3.23s-3.23 1.45-3.23 3.23c0 1.062.522 1.999 1.316 2.588-1.417.6-2.602 1.84-3.227 3.508-.194.517.068 1.093.584 1.288.517.19 1.094-.068 1.29-.585.595-1.591 1.878-2.62 3.267-2.62 1.288 0 2.48.892 3.121 2.29-.262.402-.495.834-.674 1.309-.193.517.07 1.093.586 1.287.116.043.234.064.351.064.404 0 .786-.247.936-.65.597-1.59 1.88-2.618 3.268-2.618 1.39 0 2.672 1.028 3.27 2.619.193.518.77.777 1.287.585.516-.194.778-.77.585-1.288"
        />
      </g>
    </svg>
  )
};
