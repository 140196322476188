import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M4.124 13h7.752c-.277-1.028-.984-1.625-1.722-2.248-.805-.681-1.718-1.452-1.727-2.745.009-1.306.922-2.077 1.726-2.758.739-.624 1.445-1.221 1.723-2.249H4.124c.277 1.028.984 1.625 1.723 2.249.804.681 1.716 1.452 1.726 2.743-.01 1.308-.922 2.079-1.727 2.759-.738.624-1.445 1.221-1.722 2.249M13 15H3c-.553 0-1-.447-1-1 0-2.617 1.477-3.865 2.555-4.776.63-.533 1.015-.876 1.018-1.232-.003-.339-.388-.683-1.017-1.215C3.477 5.865 2 4.617 2 2c0-.553.447-1 1-1h10c.553 0 1 .447 1 1 0 2.617-1.477 3.865-2.556 4.776-.629.533-1.015.876-1.017 1.231.002.341.388.684 1.018 1.217C12.523 10.136 14 11.384 14 14c0 .553-.447 1-1 1"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M6.066 19.998c.292-2.109 1.506-3.209 2.686-4.276 1.101-.997 2.24-2.028 2.251-3.73-.01-1.688-1.15-2.719-2.25-3.716-.105-.092-.208-.187-.31-.282h7.115c-.102.095-.205.19-.308.282-1.1.997-2.24 2.028-2.25 3.73.01 1.688 1.148 2.719 2.25 3.716 1.18 1.067 2.394 2.167 2.686 4.276H6.066zM17.936 4c-.112.812-.364 1.474-.696 2.042-.078-.02-.153-.048-.237-.048H6.731C6.414 5.436 6.175 4.789 6.066 4h11.87zm-1.344 10.238c-1.014-.918-1.588-1.474-1.593-2.232.005-.772.58-1.329 1.593-2.247 1.44-1.303 3.411-3.087 3.411-6.759 0-.553-.448-1-1-1H5c-.553 0-1 .447-1 1 0 3.672 1.97 5.456 3.41 6.759 1.013.918 1.588 1.475 1.593 2.233-.005.772-.58 1.328-1.593 2.246C5.97 15.542 4 17.326 4 20.998c0 .553.447 1 1 1h14.003c.552 0 1-.447 1-1 0-3.672-1.972-5.457-3.41-6.76z"
        />
      </g>
    </svg>
  )
};
