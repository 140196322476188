import React from 'react';

export default {
  sm: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H16V16H0z" />
        <path
          className="icon-color"
          d="M6 13.003c-.267 0-.522-.105-.709-.293L1.292 8.706c-.39-.39-.39-1.024.001-1.415.391-.389 1.024-.39 1.415.001L6 10.588l7.296-7.295c.391-.39 1.023-.39 1.414 0 .391.391.391 1.023 0 1.414L6.706 12.71c-.187.188-.44.293-.707.293"
        />
      </g>
    </svg>
  ),
  md: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          className="icon-color"
          d="M8.999 19c-.266 0-.52-.105-.707-.293l-5.999-6.003c-.391-.391-.391-1.024 0-1.414.391-.391 1.023-.391 1.414 0l5.292 5.296L20.291 5.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-11.999 12c-.187.188-.441.293-.707.293"
        />
      </g>
    </svg>
  )
};
